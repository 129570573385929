<template>
  <b-container class="error py-5">
    <b-row>
      <b-col>
        <h1>Error {{ $route.meta.error }}</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped>

</style>
